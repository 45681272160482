.buyOnlineCta {
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .button {
    margin: 0 auto 0;
    min-width: 120px;
  }

  @media (--tablet) {
    .button {
      width: fit-content;
    }
  }

  @media (--desktop) {
    .button {
      margin: 0;
      width: auto;
    }
    align-items: normal;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.or {
  align-items: center;
  background: linear-gradient(
    180deg,
    white calc(50% - 1px),
    var(--color-tarmac-grey-300) calc(50%),
    white calc(50% - 1px)
  );
  color: #000;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  justify-content: center;
  margin: var(--spacing-2) 0 0;
  text-transform: uppercase;
  width: 100%;

  @media (--desktop) {
    background: linear-gradient(var(--color-tarmac-grey-300), var(--color-tarmac-grey-300))
      no-repeat center/1px 100%;
    flex-direction: row;
    margin: 0 auto;
    width: auto;
  }
}

.copy {
  background: #fff;
  display: inline-block;
  padding: var(--spacing-2);

  @media (--desktop) {
    padding: var(--spacing-1);
  }
}
