.makeModelWrapper {
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }

  &.active {
    & .iconWrapper {
      & .rotate {
        transform: rotate(180deg);
      }
    }
  }
}

.dropdownWrapper {
  display: flex;
  justify-content: space-between;
  color: var(--color-tarmac-grey-700);
  min-height: var(--form-control-min-height);
  align-items: center;
  cursor: pointer;
  height: 56px;

  & .makeValue {
    max-width: fit-content;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    line-height: 2;
  }

  & .iconWrapper {
    width: var(--spacing-6);
    height: var(--spacing-6);
    transform: rotate(270deg);
  }

  @media (--tablet) {
    padding: 0 12px;
    height: auto;

    &.first {
      padding-left: 0;
    }

    & .iconWrapper {
      transform: rotate(0deg);

      & .rotate {
        transition: all 0.5s;
      }
    }
  }
}

.searchWrapper {
  && {
    position: relative;
    z-index: 3;
    background-color: var(--color-championship-white);
    font-weight: 200;

    @media (--mobile) {
      background-color: transparent;
      padding: var(--spacing-2) var(--spacing-5) var(--spacing-5);
    }

    @media (--tablet) {
      padding: var(--spacing-3);
    }

    & .searchWrapperRow {
      margin: 0;

      @media (--mobile) {
        background-color: var(--color-championship-white);
      }
    }

    & .searchCol {
      border-bottom: 1px solid var(--color-tarmac-grey-300);

      & + fieldset {
        border: none;
      }

      & input {
        padding: 0px;
      }

      & button {
        min-width: 120px;
        margin-top: var(--spacing-2);
      }

      @media (--tablet) {
        border: none;

        & input {
          padding: 10px;
        }

        & button {
          margin-top: 0;
        }
      }
    }

    & .searchCol:nth-last-child(2),
    & .searchCol:nth-last-child(1) {
      border: none;

      @media (--tablet) {
        padding-bottom: 0;
        border-right: none;
      }
    }

    & .searchCol:nth-last-child(2) {
      & fieldset {
        border: none;
      }
    }

    & .searchCol:nth-last-child(1) {
      padding-left: 0;
      @media (--mobile) {
        padding: 0;
      }
    }
  }

  &.noParent {
    max-width: 720px;
    margin: calc((-84px / 2) - 80px) auto 0;
    box-shadow: 0px 4px 12px rgba(38, 38, 38, 0.08);
    z-index: 3;

    & .searchCol:nth-last-child(3) {
      & fieldset {
        border: none;
      }
    }

    @media (--mobile) {
      margin-top: -253px;
      box-shadow: none;
    }

    & .searchWrapperRow {
      @media (--mobile) {
        box-shadow: 0px 4px 12px rgba(38, 38, 38, 0.08);
        padding: var(--spacing-2) var(--spacing-5) var(--spacing-5);
      }
    }
  }

  &.noParentWide {
    max-width: 920px;
    margin: calc((-84px / 2) - 80px) auto 0;
    box-shadow: 0px 4px 12px rgba(38, 38, 38, 0.08);
    z-index: 3;

    & .searchCol:nth-last-child(3) {
      & fieldset {
        border: none;
      }
    }

    & .searchWrapperRow {
      @media (--mobile) {
        box-shadow: 0px 4px 12px rgba(38, 38, 38, 0.08);
        padding: var(--spacing-2) var(--spacing-5) var(--spacing-5);
      }
    }

    @media (--mobile) {
      margin-top: -253px;
      box-shadow: none;
    }
  }

  &.wrapperWithText {
    margin: var(--spacing-10) 0;
    padding: 0;
    max-width: 100%;
    z-index: 3;
    @media (--tablet) {
      padding: var(--spacing-3);
    }

    @media (--desktop-m) {
      max-width: 720px;
      margin: calc((-84px / 2) - 40px) calc(560px - 20px) 0;
      box-shadow: 0px 4px 12px rgba(38, 38, 38, 0.08);
    }

    & .searchWrapperRow {
      @media (--mobile) {
        box-shadow: 0px 4px 12px rgba(38, 38, 38, 0.08);
        padding: var(--spacing-2) var(--spacing-5) var(--spacing-5);
      }
    }
  }
}

.postcodeWrapper {
  & > div {
    width: 100%;
  }

  & :global(.searchWidgetPostcode) {
    height: 56px;
  }

  & fieldset + div {
    padding-right: 0;
  }

  @media (--tablet) {
    height: 48px;

    & :global(.searchWidgetPostcode) {
      height: auto;
    }

    & fieldset + div {
      padding-right: var(--spacing-2);
    }

    & span[role='alert'] {
      top: 22px;
    }
  }
}

.modalContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;

  @media (--tablet-l) {
    height: 600px;
    width: 800px;
  }

  iframe {
    width: 100%;
    height: 100vh;
    border: none;
  }
}

.modalBody {
  padding: 0;
}

@media (--tablet) {
  .searchWrapper {
    & > .compactSearch {
      & > .searchCol {
        border-right: none;
        border-bottom: 1px solid var(--color-tarmac-grey-300);
        & .dropdownWrapper {
          padding: 0 12px;
          & + div {
            top: 46%;
          }
        }
      }
      & > .searchCol:first-of-type {
        & .dropdownWrapper {
          & + div {
            top: 23%;
          }
        }
      }
    }
  }
}
